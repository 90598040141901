import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceList: [],
  loading: false,
  error: "",
  singleService: {},
  adding: false,
  serviceFormat: "basic",
};

const serviceSlice = createSlice({
  name: "service",
  initialState: initialState,
  reducers: {
    getServiceList: (state, action) => {
      state.loading = true;
    },
    getServiceListSuccess: (state, action) => {
      state.serviceList = action.payload;
      state.loading = false;
    },
    getServiceListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addServiceData: (state, action) => {},
    getSingleService: (state, action) => {
      state.loading = true;
    },
    getSingleServiceSuccess: (state, action) => {
      state.singleService = action.payload;
      state.loading = false;
    },
    getSingleServiceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteService: (state, action) => {
      state.adding = true;
    },
    deleteServiceSuccess: (state, action) => {
      state.adding = false;
    },
    deleteServiceFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    setServiceFormat: (state, action) => {
      state.serviceFormat = action.payload;
    },
  },
});

export const {
  getServiceList,
  getServiceListSuccess,
  getServiceListFailure,
  addServiceData,
  getSingleService,
  getSingleServiceSuccess,
  getSingleServiceFailure,
  deleteService,
  deleteServiceSuccess,
  deleteServiceFailure,
  setServiceFormat,
} = serviceSlice.actions;

export default serviceSlice.reducer;
