import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addEmployeeData,
  changeEmployeeStatus,
  deleteEmployee,
  deleteEmployeeFailure,
  deleteEmployeeSuccess,
  getEmployeeList,
  getEmployeeListFailure,
  getEmployeeListSuccess,
  getEmployeeVacation,
  getEmployeeVacationFailure,
  getEmployeeVacationSuccess,
  getSearchEmployee,
  getSearchEmployeeFailure,
  getSearchEmployeeSuccess,
  getSingleEmployee,
  getSingleEmployeeFailure,
  getSingleEmployeeSuccess,
  storeEmployee,
  uploadEmployeeData,
  uploadEmployeeDataFailure,
  uploadEmployeeDataSuccess,
} from "./EmployeeSlice";

function* onGetEmployee({
  payload: {
    page = 1,
    limit = 10,
    concernCompany = "",
    department = "",
    designation = "",
    employmentType = "",
    probationPermanentStatus = "",
    isAssessor = "",
    status = "",
  },
}) {
  try {
    const url = `/employee/data/?limit=${limit}&pageNo=${page}&concernCompany=${concernCompany}&department=${department}&designation=${designation}&employmentType=${employmentType}&probationPermanentStatus=${probationPermanentStatus}&isAssessor=${isAssessor}&status=${status}`;
    const response = yield call(get, url);
    // console.log("responseee", response);
    if (response) {
      yield put(getEmployeeListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Employee Failed";
    yield put(getEmployeeListFailure({ message }));
  }
}

function* onAddEmployee({
  payload: {
    data,
    options: { id, currentTab, setSubmitting, history },
  },
}) {
  try {
    const url = id ? `/employee/${id}` : `/employee`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Employee ${id ? "updated" : "added"} Successfully`);
      console.log(response);
      yield put(storeEmployee({ name: "currentTab", value: currentTab }));
      if (!id)
        yield put(
          storeEmployee({ name: "employeeId", value: response.employee._id })
        );
      setSubmitting
        ? setSubmitting(false)
        : yield put(storeEmployee({ name: "adding", value: false }));
      history && history.push("/admin/employee");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Employee Failed`;
    toaster("error", message);
    setSubmitting
      ? setSubmitting(false)
      : yield put(storeEmployee({ name: "adding", value: false }));
  }
}

function* onChangeEmployeeStatus({
  payload: {
    data,
    options: { id, setSubmitting, toggle },
  },
}) {
  try {
    const url = `/employee/update-employee-status/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Employee status change successfully`);
      setSubmitting(false);
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Change employee status failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onGetSingleEmployee({ payload: { id } }) {
  try {
    const url = `/employee/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleEmployeeSuccess(response?.employee));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single employee failed";
    toaster("error", message);
    yield put(getSingleEmployeeFailure({ message }));
  }
}

function* onGetSearchEmployee({ payload: { searchVal, limit, page } }) {
  try {
    const url = `/employee/employeeSearch?searchKeyword=${searchVal}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    // console.log(`responsesearch`, response);
    if (response) {
      yield put(getSearchEmployeeSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get search employee failed";
    toaster("error", message);
    yield put(getSearchEmployeeFailure({ message }));
  }
}

function* onDeleteEmployee({
  payload: { id, setCurrentPage, setHandleList, isSubmit },
}) {
  try {
    const url = `/employee/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteEmployeeSuccess(response));
      toaster("success", "Employee Deleted successfully");
      // setSelectedUser(null);
      // setCurrentPage(1);
      // setHandleList(10);
      // yield put(getEmployeeList({ page: 1, limit: 10 }));
      isSubmit(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete employee failed";
    toaster("error", message);
    yield put(deleteEmployeeFailure({ message }));
  }
}

function* onGetEmployeeVacation({ payload: { id, year = "" } }) {
  try {
    const currentyear = new Date().getFullYear();
    const vacationUrl = `/leave-application/employee-leave-summary?employeeId=${id}&year=${
      year || currentyear
    }`;
    const vacation = yield call(get, vacationUrl);
    // console.log(`vacation`, vacation);
    // console.log(`response`, response);
    if (vacation) {
      yield put(getEmployeeVacationSuccess(vacation));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single employee vacation failed";
    toaster("error", message);
    yield put(getEmployeeVacationFailure({ message }));
  }
}

function* onAddUploadEmployee({
  payload: {
    data,
    options: { toggle, setUploading, setProgress },
  },
}) {
  try {
    const url = "/employee/excel-import";
    let responseLength = 0;

    for (let i = 0; i < data?.length; i++) {
      const obj = data[i];
      const response = yield call(post, url, obj);

      if (response) {
        responseLength += 1;
        setProgress(parseInt(((i + 1) / data?.length) * 100));
      }
    }

    if (data?.length === responseLength) {
      toaster("success", `${data?.length} employee data upload successfully`);
      yield put(uploadEmployeeDataSuccess());
      yield toggle(true);
    } else {
      toaster("error", "Some employee data upload failed");
    }

    yield setUploading(false);
  } catch (error) {
    const message =
      error?.response?.data?.message || `Employee data upload failed`;
    toaster("error", message);
    yield put(uploadEmployeeDataFailure({ message }));
    yield setUploading(false);
  }
}

export function* employeeSaga() {
  yield takeEvery(getEmployeeList.type, onGetEmployee);
  yield takeEvery(addEmployeeData.type, onAddEmployee);
  yield takeEvery(getSingleEmployee.type, onGetSingleEmployee);
  yield takeEvery(deleteEmployee.type, onDeleteEmployee);
  yield takeEvery(getSearchEmployee.type, onGetSearchEmployee);
  yield takeEvery(getEmployeeVacation.type, onGetEmployeeVacation);
  yield takeEvery(uploadEmployeeData.type, onAddUploadEmployee);
  yield takeEvery(changeEmployeeStatus.type, onChangeEmployeeStatus);
}
