import { call, put, takeEvery } from "redux-saga/effects";
import { axiosApi, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  forgetPassword,
  logOutUser,
  login,
  loginFailure,
  loginSuccess,
  registerApplicant,
  resetPassword,
} from "./loginSlice";

function* onLoginUser({
  payload: { history, data, url = "/admin-auth/login", redirect = "/" },
}) {
  try {
    const response = yield call(post, url, data);
    console.log(`function*onLoginUser ~ response`, response);
    if (response) {
      let role = "employee",
        roles = [];
      if (response?.userType === "employee") {
        if (response?.user?.headOfTheDepartment === "Yes") {
          // role = "headOfDepartment";
          roles.push("headOfDepartment");
        }
        if (response?.user?.isAssessor === "Yes") {
          // role = "assessor";
          roles.push("assessor");
        }
        if (response?.user?.isLineManager === "Yes") {
          // role = "lineManager";
          roles.push("lineManager");
        }
        if (
          response?.user?.headOfTheDepartment === "No" &&
          response?.user?.isAssessor === "No" &&
          response?.user?.isLineManager === "No"
        ) {
          roles.push("employee");
        }
      } else {
        role = response?.user?.role || response.userType;
      }

      // console.log(role, roles);

      yield put(
        loginSuccess({
          token: response?.token,
          role: role,
          roles,
          user: response?.user,
        })
      );
      axiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.token}`;
      toaster("success", "Login Successful");
      history.push(redirect);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Login Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
  }
}

function* onRegister({ payload: { history, data, setSubmitting } }) {
  try {
    const url = "/applicant-panel/profile/applicant-sign-up";
    const response = yield call(post, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Successful! Check your email");
      setSubmitting && setSubmitting(false);
      history.push(`/applicant/login`);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Registration Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
    setSubmitting && setSubmitting(false);
  }
}

function* onLogOutUser({ payload: { history, role } }) {
  if (role === "applicant") {
    yield history.push("/applicant/login");
  } else if (
    role === "employee" ||
    role === "assessor" ||
    role === "lineManager" ||
    role === "headOfDepartment"
  ) {
    yield history.push("/employee/login");
  } else {
    yield history.push("/login");
  }
}

function* onForgotPassword({
  payload: {
    data,
    options: { toggle, resetForm, setSubmitting, role },
  },
}) {
  try {
    const url =
      role === "employee"
        ? `/employee-auth/forgot-password`
        : "/career-applicant-auth/forgot-password";
    const response = yield call(post, url, data);

    if (response) {
      toggle(true);
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Token send failed, please try again`;
    toaster("error", message);
    toggle(false);
    yield setSubmitting(false);
  }
}

function* onResetPassword({
  payload: {
    data,
    options: { token, history, setSubmitting, resetForm, role },
  },
}) {
  try {
    const url =
      role === "employee"
        ? `/employee-auth/reset-password/${token}`
        : `/career-applicant-auth/reset-password/${token}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Change password successfully`);

      yield put(
        login({
          history,
          data: {
            userName: response?.user?.email,
            password: data?.password,
          },
          url:
            role === "employee"
              ? "/employee-auth/login"
              : "/career-applicant-auth/login",
          redirect:
            role === "employee"
              ? "/employee/employee-profile"
              : "/applicant/dashboard",
        })
      );
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    yield setSubmitting(false);
    const message =
      error?.response?.data?.message ||
      `Change password failed, please try again`;
    toaster("error", message);
  }
}

// function* onUpdateProfile({ payload: { data, setSubmitting, id, toggle } }) {
//   try {
//     const url = `/applicant-panel/profile/${id}`;
//     const response = yield call(patch, url, data);
//     // console.log(`response`, response);
//     if (response) {
//       toaster("success", "Update profile successful");
//       setSubmitting && setSubmitting(false);
//       toggle && toggle(true);
//     }
//   } catch (err) {
//     const message = err?.response?.data?.message || "update profile Failed";
//     toaster("error", message);
//     yield put(loginFailure({ message }));
//     setSubmitting && setSubmitting(false);
//   }
// }

// function* onUpdateApplicantPassword({
//   payload: { data, setSubmitting, toggle },
// }) {
//   try {
//     const url = `/career-applicant-auth/updateMyPassword`;
//     const response = yield call(patch, url, data);
//     // console.log(`response`, response);
//     if (response) {
//       toaster("success", "Update profile successful");
//       setSubmitting && setSubmitting(false);
//       toggle && toggle(true);
//     }
//   } catch (err) {
//     const message = err?.response?.data?.message || "update profile Failed";
//     toaster("error", message);
//     yield put(loginFailure({ message }));
//     setSubmitting && setSubmitting(false);
//   }
// }

// eslint-disable-next-line import/prefer-default-export
export function* loginSaga() {
  yield takeEvery(login.type, onLoginUser);
  yield takeEvery(registerApplicant.type, onRegister);
  yield takeEvery(forgetPassword.type, onForgotPassword);
  yield takeEvery(resetPassword.type, onResetPassword);
  // yield takeEvery(updateProfile.type, onUpdateProfile);
  // yield takeEvery(updateApplicantPassword.type, onUpdateApplicantPassword);
  yield takeEvery(logOutUser.type, onLogOutUser);
}
