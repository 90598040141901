import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  error: "",
  adding: false,
  reRender: false,
  allQuestions: [],
  currentIndex: 0,
  isDone: false,
  isRouteChange: true,
  isPlayAgain: false,
  answerId: "",
  examStartId: "",
  saving: false,
  writingAns: [],
  listeningAudio: [],
  writingAnswerList: {},
  assessmentData: {},
  examCount: 0,
  clues: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  },
  ans: [],
};

const corporateExamSlice = createSlice({
  name: "corporateExam",
  initialState: initialState,
  reducers: {
    storeCorporateExamQuestion: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    storeCorporateExamWithClues: (state, { payload }) => {
      state.ans = payload.ans;
      state.clues = payload.clues;
    },
    /// all question
    getAllExamQuestions: (state, action) => {
      state.loading = true;
    },
    getAptitudeExamQuestions: (state, action) => {
      state.loading = true;
    },
    getAllExamQuestionsSuccess: (
      state,
      { payload: { questions, startExam, type, isDone, assessmentData } }
    ) => {
      const { examStartId, answerId, _id } = startExam;
      console.log("startExam", startExam);
      let notePad = [];
      let answer = [];
      let writingAns = [];
      let listeningAudio = [];
      questions?.forEach((data, i) => {
        writingAns.push({
          answer: "",
          photo: "",
          havePhoto: 0,
          isImage: false,
        });
        if (type === "aptitude") {
          answer.push({
            answer: [],
            mark: 0,
            explanation: "",
            rightAnswer: [],
            questionNumber: "",
          });
        }
        data?.data?.forEach((el) => {
          notePad.push("");
          listeningAudio.push(false);
          if (el?.Que) {
            el?.Que?.question?.forEach((ques) => {
              answer.push({
                answer: [],
                mark: 0,
                explanation: "",
                rightAnswer: [],
                questionNumber: "",
              });
            });
          }
        });
      });
      state.writingAns = writingAns;
      state.notePad = notePad;
      state.ans = answer;
      state.allQuestions = questions || [];
      state.listeningAudio = listeningAudio;
      state.answerId = answerId || "";
      // state.examStartId = type === "writing" ? _id : examStartId;
      state.examStartId = type === "writing" ? _id : examStartId;
      state.isRouteChange = questions?.length ? true : false;
      state.isDone = isDone;
      state.isPlayAgain = !state?.isPlayAgain;
      state.assessmentData = assessmentData;
      state.loading = false;
    },
    getAllExamQuestionsFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    /// submit aptitude ans one by one
    submitAptitudeAnsOneByOne: (state, action) => {
      state.saving = true;
    },
    /// submit ans one by one
    submitAnsOneByOne: (state, action) => {
      state.saving = true;
    },
    submitAnsOneByOneSuccess: (state, action) => {
      state.saving = false;
    },
    submitAnsOneByOneFailure: (state, action) => {
      state.saving = false;
    },
    /// submit exam data
    submitExamData: (state, action) => {
      state.adding = true;
    },
    submitExamDataSuccess: (state, action) => {
      state.adding = false;
    },
    submitExamDataFailure: (state, action) => {
      state.adding = false;
    },
    /// submit exam data
    resubmitExamData: (state, action) => {
      state.adding = true;
    },
    resubmitExamDataSuccess: (state, action) => {
      state.adding = false;
    },
    resubmitExamDataFailure: (state, action) => {
      state.adding = false;
    },
    /// submit exam data
    getWritingAnswer: (state, action) => {
      state.loading = true;
    },
    getWritingAnswerSuccess: (state, { payload }) => {
      state.loading = false;
      state.writingAnswerList = payload;
    },
    getWritingAnswerFailure: (state, action) => {
      state.loading = false;
    },
    /// submit exam data
    getExamSubmitCount: (state, action) => {
      state.loading = true;
    },
    getExamSubmitCountSuccess: (state, { payload }) => {
      state.loading = false;
      state.examCount = payload;
    },
    getExamSubmitCountFailure: (state, action) => {
      state.loading = false;
    },
    submitWritingAnswer: (state, action) => {},
    examFinish: (state, action) => {},
    resetAll: (state) => {
      state.loading = true;
      state.error = "";
      state.adding = false;
      state.reRender = false;
      state.allQuestions = [];
      state.currentIndex = 0;
      state.isDone = false;
      state.isRouteChange = true;
      state.answerId = "";
      state.examStartId = "";
      state.saving = false;
      state.writingAns = [];
      state.listeningAudio = [];
      state.clues = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
      };
      state.ans = [];
    },
    reRenderData: (state) => {
      state.reRender = !state.reRender;
    },
  },
});

export const {
  getAllExamQuestions,
  getAllExamQuestionsSuccess,
  getAllExamQuestionsFailure,
  submitAnsOneByOne,
  submitAptitudeAnsOneByOne,
  submitAnsOneByOneSuccess,
  submitAnsOneByOneFailure,
  submitExamData,
  submitExamDataSuccess,
  submitExamDataFailure,
  getWritingAnswer,
  getWritingAnswerSuccess,
  getWritingAnswerFailure,
  getExamSubmitCount,
  getExamSubmitCountSuccess,
  getExamSubmitCountFailure,
  resubmitExamData,
  resubmitExamDataSuccess,
  resubmitExamDataFailure,
  storeCorporateExamQuestion,
  storeCorporateExamWithClues,
  getAptitudeExamQuestions,
  resetAll,
  submitWritingAnswer,
  reRenderData,
  examFinish,
} = corporateExamSlice.actions;

export default corporateExamSlice.reducer;
