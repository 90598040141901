import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAssessmentReview,
  getAssessmentAssignList,
  getAssessmentAssignListFailure,
  getAssessmentAssignListSuccess,
  getAssessmentReview,
  getAssessmentReviewFailure,
  getAssessmentReviewSuccess,
} from "./AssessmentSlice";

function* onGetAssessmentAssign({
  payload: {
    page = 1,
    limit,
    jobPostId = "",
    status = "",
    assessmentId = "",
    resultStatus = "",
  },
}) {
  try {
    const url = `/employee-panel/assessment/get-assessment-assigned-list?limit=${limit}&pageNo=${page}&status=${status}&jobPostId=${jobPostId}&assessmentId=${assessmentId}&resultStatus=${resultStatus}`;
    const response = yield call(get, url);
    // console.log("responseee", response);
    yield put(getAssessmentAssignListSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get assessment assign failed";
    toaster("error", message);
    yield put(getAssessmentAssignListFailure({ message }));
  }
}

function* onGetAssessmentReview({ payload: { id } }) {
  try {
    const url = `/employee-panel/assessment/get-assessment-assigned-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAssessmentReviewSuccess(response?.assessment));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get assessment review failed";
    toaster("error", message);
    yield put(getAssessmentReviewFailure({ message }));
  }
}

function* onAddAssessmentReview({
  payload: {
    data,
    options: { setSubmitting, resetForm, id, assessmentId, history },
  },
}) {
  try {
    const url = `/employee-panel/assessment/assessment-review-and-remarks/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      yield put(getAssessmentReview({ id: assessmentId }));
      toaster("success", `Assessment Review Added Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push("/employee/assessment-review");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Assessment review added Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* employeeAssessmentSaga() {
  yield takeEvery(getAssessmentAssignList.type, onGetAssessmentAssign);
  yield takeEvery(getAssessmentReview.type, onGetAssessmentReview);
  yield takeEvery(addAssessmentReview.type, onAddAssessmentReview);
}
