import { call, takeEvery } from "redux-saga/effects";
import { patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  updateApplicantPassword,
  updateProfile,
} from "./ApplicantProfileSlice";

function* onUpdateProfile({ payload: { data, setSubmitting, id, toggle } }) {
  try {
    const url = `/applicant-panel/profile/${id}`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Update profile successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Update profile Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

function* onUpdateApplicantPassword({
  payload: { data, setSubmitting, toggle },
}) {
  try {
    const url = `/career-applicant-auth/updateMyPassword`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Update password successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);

      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 500);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Update password Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

export function* applicantProfileSaga() {
  yield takeEvery(updateProfile.type, onUpdateProfile);
  yield takeEvery(updateApplicantPassword.type, onUpdateApplicantPassword);
}
